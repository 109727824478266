<input
  [disableControl]="disabledState"
  type="hidden"
  [matDatepicker]="title"
  [(ngModel)]="value"
  id="input-start-date"
  (dateChange)="onChange($event)"
/>
<input
  matInput
  [textMask]="mask"
  [disableControl]="disabledState"
  style="padding-right:15px"
  [ngModel]="value | datePickerFormat"
  (blur)="onBlur($event)"
/>
<mat-datepicker-toggle matSuffix [for]="title"></mat-datepicker-toggle>
<mat-datepicker
  touchUi
  (selectedChanged)="onChange($event)"
  #title
></mat-datepicker>
<mat-hint>(MMDDYYYY)</mat-hint>
